


































import { ProfileModel } from '@/models/profile-model'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'unfollow-dialog': () => import('@/components/dialogs/unfollow-dialog.vue'),
  },
})
export default class FollowButton extends Vue {
  @Prop({}) action?: 'follow' | 'unfollow'
  @Prop({}) loading?: boolean
  @Prop() profile!: ProfileModel
  @Prop({default: 112}) width!: number

  openUnFollowDialog = false

  setOpenUnFollowDialog(value) {
    this.openUnFollowDialog = value
  }

  unFollow() {
    this.$emit('unFollow')
    this.setOpenUnFollowDialog(false)
  }
}
